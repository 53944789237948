import React from 'react'
//import './style.scss'
import Header from '../../components/template/contentheader/';
import Cards from './topcards'
import LayoutChart from './layoutchart'
function Layoutdash(props) {
    return (
        <div className="content">
            <Header title="Dashboard" />
            <Cards list={props.cards} />
            <LayoutChart grafica={props.grafica} />
        </div>
    )
}
export default Layoutdash;