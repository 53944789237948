import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal';
//import "bootstrap/dist/css/bootstrap.min.css";
import API from './../../util/api'
import CONSTANTS from '../../util/constants'
import { Portal } from '@material-ui/core'
const ModalProfile = (props) => {

    const [description, setDescription] = useState('')

    const [modules, setModules] = useState([]) 

    const [modulesSelected, setModulesSelected] = useState([])
    
    const initialValuesError = {
        description:'',
        modules: ''
    }
    const [formError, setFormError] = useState(initialValuesError)
    
    const handleModulesSelected = (e) => {
        let arrMod = modulesSelected
        if (arrMod.includes(e.target.value))
        {
            const index = arrMod.indexOf(e.target.value)
            arrMod.splice(index, 1);
        }else{
            arrMod.push(e.target.value)
        }
        setModulesSelected(arrMod)
        console.log(modulesSelected)
    }
    const handleModules = (modules) => {
        setModules(modules)
    }
    const handleDescription = (e) => {
        setDescription(e.target.value)
    }    
    /*const ListOptionsCategories = categories.map((categoryItem)=>(
        <option key={categoryItem.id} value={categoryItem.id}>{categoryItem.description}</option>
    ))*/

    useEffect(()=>{
        cleanValuesFieldsForm()
        cleanFieldsFormErrors()
        //get data
        if (props.show) {
            props.handleProfileSave(0)
            const ENDPOINT = CONSTANTS.url_api+'profile/create/'
            axios.get(ENDPOINT)
                 .then(res => {
                     let response = res.data;
                     handleModules(response.modules)
                     
                 })
        }

        if (props.profile !== null) {
            const {profile} = props
            setDescription(profile.description)
            setModules(profile.modules)
        }

    },[props.show])


    const save = () => {
        
        props.handleLoading(true)

        cleanFieldsFormErrors()

        const formData = new FormData()
        
        formData.append("description", description)

        const {profile} = props
        if (profile !== null) {
            formData.append("id", profile.id)
        }

        const ENDPOINT = CONSTANTS.url_api+'saveProfile'

        axios.post(ENDPOINT, formData)
            .then((res)=>{

                props.handleLoading(false)

                const response = res.data
                if (response.status == CONSTANTS.st_api_ok) {
                    props.notify.show('Perfil registrado.','success',2500)
                    props.handleProfileSave(1)
                    props.setProfile(null)
                    props.toggleModal(false)
                }else{
                    if (response.typeError == '1') {
                        props.notify.show('Ups, verfifique datos.','error',2500)
                        addFieldsFormErrors(response.errors)
                    }else{

                    }
                }
            })        
    }

    const addFieldsFormErrors = (errors) => {
        API.addErrorFieldsForm(errors)
        setFormError(errors)       
    }
    const cleanFieldsFormErrors = () => {
        API.cleanFieldsForm()
        setFormError({
            description:'',
        })
    }

    const cleanValuesFieldsForm = () => {
        setDescription("")
        setModules([])
    }

    const closeModal = () => {
        props.toggleModal(false)
        props.setProfile(null);
    }

    return (
        <Modal show={props.show} size={props.size}>
            <Modal.Header closeButton={false}>
                <Modal.Title>
                    Perfil
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="form-profile" className="form">

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="name">Descripción</label>
                                <input type="text" name="description" value={description} id="description" onChange={handleDescription} className='form-control form-control-sm'/>
                                <div className="invalid-feedback">
                                    {formError.description}
                                </div>
                            </div>                            
                        </div>
                        <div className="col-sm-12">
                            <ul>
                                {
                                    modules?
                                    modules.map((moduleFather)=>{
                                        if (moduleFather.childs.length == 0) {
                                            return (
                                                <li key={moduleFather.id}>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="modulo[]" value={moduleFather.id} onChange={handleModulesSelected} />
                                                        <label className="form-check-label" >
                                                        {moduleFather.description}
                                                        </label>
                                                    </div>                                                
                                                </li>
                                            )
                                        }else{
                                            return (
                                                <li key={moduleFather.id}>
                                                    {moduleFather.description}
                                                    <ul>
                                                    {
                                                        moduleFather.childs.map((child)=>{
                                                            return(
                                                                <li key={child.id}>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" name="modulo[]" value={child.id} onChange={handleModulesSelected} />
                                                                        <label className="form-check-label" >
                                                                        {child.description}
                                                                        </label>
                                                                    </div>                                                
                                                                </li>
                                                            )
                                                        })
                                                    
                                                    }
                                                    </ul>
                                                </li>
                                            )                                            
                                        }
                                    })
                                    :''
                                }
                            </ul>
                        </div>
                    </div>                   
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-sm btn-danger" onClick={closeModal}>cerrar</button>
                <button className="btn btn-sm btn-primary" onClick={save} disabled={props.isLoading?true:false}>Guardar</button>
            </Modal.Footer>

        </Modal>
    )
}

export default ModalProfile 