
export const data = {
    "sidebar": [
        {
            "name": "Dashboard",
            "url": "dashboard",
            "icon": "tachometer-alt"
        },
        {
            "name": "Contacto",
            "url": "contacto",
            "icon": "users",
            "children": [
                {
                    "name": "Usuarios",
                    "url": "usuarios"
                },
                {
                    "name": "clientes",
                    "url": "clientes"
                }
            ]
        },
        {
            "name": "Ingresos",
            "url": "ingresos",
            "icon": "border-all"
        },
        {
            "name": "Gastos",
            "url": "gastos",
            "icon": "heart",
            "children": [
                {
                    "name": "Compras",
                    "url": "compras"
                },
                {
                    "name": "Egreso",
                    "url": "egreso"
                },
                {
                    "name": "Contacto",
                    "url": "contacto"
                }
            ]
        },
        {
            "name": "Inventario",
            "url": "inventario",
            "icon": "shopping-basket"
        },
        {
            "name": "Reporte",
            "url": "reporte",
            "icon": "chart-line"
        }
    ],
    "dashboard":
    {
        "cards": [
            {
                "title": "Total User",
                "quantity": '40,689',
                "toup": 10,
                "icon": "user-friends",
                "color": "#837ffc"
            },
            {
                "title": "Total Order",
                "quantity": 10293,
                "toup": 1.3,
                "icon": "cube",
                "color": "#fdc53d"
            },
            {
                "title": "Total Sales",
                "quantity": '89,000',
                "toup": -4.3,
                "icon": "chart-line",
                "color": "#5adc99"
            },
            {
                "title": "Total Pending",
                "quantity": 2040,
                "toup": 10,
                "icon": "history",
                "color": "#fc9a79"
            },
        ],
        "grafica": {
            "label": ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'],
            "value": [0, 6, 4, 10, 8, 14, 11, 15, 17, 16, 18, 17]
        }
    }
    ,
    "categories": [
        {
            "id": 1,
            "name": "Accesorios"
        },
        {
            "id": 2,
            "name": "Deportivo"
        },
        {
            "id": 3,
            "name": "Libros"
        },
        {
            "id": 4,
            "name": "Equipajes"
        },
        {
            "id": 5,
            "name": "Electrónicos"
        },
        {
            "id": 6,
            "name": "Tecnológicos"
        }
    ],
    "marca": [
        {
            "id": 1,
            "name": "Adidas"
        },
        {
            "id": 2,
            "name": "Nike"
        },
        {
            "id": 3,
            "name": "Puma"
        },
        {
            "id": 4,
            "name": "Rebook"
        },
        {
            "id": 5,
            "name": "Samsung"
        }
    ],
    "colors": [
        {
            "id": 1,
            "name": "red"
        },
        {
            "id": 2,
            "name": "blue"
        },
        {
            "id": 3,
            "name": "green"
        },
        {
            "id": 4,
            "name": "yellow"
        }
    ],
    "productos": [
        {
            "id": 1,
            "nombre": "Polo negro Alan walker",
            "descripcion": "Polo de alan walker, prendas de algodón",
            "image": "http://chiry.ml/img/uploads/0a4b12af8e501838f2f5de88eab38d4f.jpg",
            "precio": 80,
            "precio_old": 100,
            "descuento": 20,
            "categoria": 2,
            "marca": 2,
            "stock": 40,
            "rating": 4,
        },
        {
            "id": 2,
            "nombre": "Zapatilla Adidas",
            "descripcion": "Zapatillas Adidas deportivas con increíbles descuetos...",
            "image": "http://chiry.ml/img/uploads/6d0d6db3c3ffac381c423224922cd10c.jpg",
            "precio": 300,
            "precio_old": 0,
            "descuento": 0,
            "categoria": 2,
            "marca": 2,
            "stock": 40,
            "rating": 3.5,
        },
        {
            "id": 3,
            "nombre": "Samsung Galaxsy",
            "descripcion": "Samsung Galaxsy s10, con la ultima tecnologia...",
            "image": "http://chiry.ml/img/uploads/c31c2438fc108bda9d428fb966783e45.jpg",
            "precio": 750,
            "categoria": 2,
            "precio_old": 1000,
            "descuento": 25,
            "marca": 5,
            "stock": 100,
            "rating": 2,
        },
        {
            "id": 4,
            "nombre": "Silla para oficina",
            "descripcion": "silla para oficinas, con increíbles descuebtos",
            "image": "http://wordpress.templatemela.com/woo/WCM01/WCM010013/WP13/wp-content/uploads/2019/02/17-264x347.jpg",
            "precio": 90,
            "precio_old": 0,
            "descuento": 0,
            "categoria": 2,
            "marca": 2,
            "stock": 50,
            "rating": 4,
        },
        {
            "id": 5,
            "nombre": "Polo negro Alan walker",
            "descripcion": "Polo de alan walker, prendas de algodón",
            "image": "http://chiry.ml/img/uploads/0a4b12af8e501838f2f5de88eab38d4f.jpg",
            "precio": 80,
            "precio_old": 100,
            "descuento": 20,
            "categoria": 2,
            "marca": 2,
            "stock": 40,
            "rating": 4,
        },
        {
            "id": 6,
            "nombre": "Zapatilla Adidas",
            "descripcion": "Zapatillas Adidas deportivas con increíbles descuetos...",
            "image": "http://chiry.ml/img/uploads/6d0d6db3c3ffac381c423224922cd10c.jpg",
            "precio": 300,
            "precio_old": 0,
            "descuento": 0,
            "categoria": 2,
            "marca": 2,
            "stock": 40,
            "rating": 3.5,
        },
        {
            "id": 7,
            "nombre": "Samsung Galaxsy",
            "descripcion": "Samsung Galaxsy s10, con la ultima tecnologia...",
            "image": "http://chiry.ml/img/uploads/c31c2438fc108bda9d428fb966783e45.jpg",
            "precio": 750,
            "categoria": 2,
            "precio_old": 1000,
            "descuento": 25,
            "marca": 5,
            "stock": 100,
            "rating": 2,
        },
        {
            "id": 8,
            "nombre": "Silla para oficina",
            "descripcion": "silla para oficinas, con increíbles descuebtos",
            "image": "http://wordpress.templatemela.com/woo/WCM01/WCM010013/WP13/wp-content/uploads/2019/02/17-264x347.jpg",
            "precio": 90,
            "precio_old": 0,
            "descuento": 0,
            "categoria": 2,
            "marca": 2,
            "stock": 50,
            "rating": 4,
        }
    ],
    "promocion": [
        {
            "id": 1,
            "nombre": "Made Refrigerator",
            "descripcion": "Now in all color varient available...",
            "image": "http://wordpress.templatemela.com/woo/WCM01/WCM010013/WP13/wp-content/uploads/2019/05/sub-banner-1.png"
        },
        {
            "id": 2,
            "nombre": "Handyman Tools",
            "descripcion": "Now in all color varient available...",
            "image": "http://wordpress.templatemela.com/woo/WCM01/WCM010013/WP13/wp-content/uploads/2019/05/sub-banner-2.png"
        }
    ],
    "oferta": { // oferta 1 sola cards
        "id": 1,
        "nombre": "Handyman Tools",
        "descripcion": "Now in all color varient available...",
        "image": "http://wordpress.templatemela.com/woo/WCM01/WCM010013/WP13/wp-content/uploads/2019/05/offer-banner-1.png"
    }

}

export default data;