import React from 'react'
import './style.scss'
function ContentHeader(props) {

    return (
        <div className="content-header">
            {/**
                <div className="title">
                                <h2> {props.title}</h2>
                            </div>
                            {props.options &&
                                <div className="options">
                                    {props.options}
                                </div>
                            }
             */}
             {props.children}
        </div>
    )
}
export default ContentHeader;