import React, { Component } from 'react'
import Layout from '../../containers/sidenav'
import Api from '../../util/api'
import { Children } from 'react';

let wrapper = document.getElementById("wrapper");
let sizesm = 600;
let sizemd = 1000;
class Sidebar extends Component {
    constructor(props) {
        super(props);
        //const url = this.props.location.pathname.split('/')[1];
        this.state = {
            list: JSON.parse(localStorage.getItem('sidebar')),
            expand: true, // sidebar 
            subexpand: false,
            element: '',
            mobile: false
        }
    }
    componentDidMount = () => {
        const element = this.props.location.pathname.split('/')[1]
        let activeItem = this.state.list.find(child => child.url === element)?.url;
        if (activeItem === undefined) {
            this.state.list.map((item) => {
                if (item.children != null) {
                    item.children.map((child) => {
                        if (child.url === element) {
                            activeItem = item.name.trim().replace(" ", "").toLocaleLowerCase();
                        }
                    })
                }
            })
        }
        this.setState({ element: activeItem })
        this.updateSide()
    }

    updateSide = () => {
        const { innerWidth: width } = window;
        this.setState({ expand: (width < sizemd) ? false : true })
        this.setState({ mobile: (width < sizesm) ? false : true })
        if (width < sizesm) {
            wrapper.classList.add("mobile")
            wrapper.classList.remove("hide")
        } else if (width < sizemd) {
            wrapper.classList.add("hide")
            wrapper.classList.remove("mobile")
        } else {
            wrapper.classList.remove("hide")
        }
    }
    useEffect = (() => { }, window.addEventListener('resize', this.updateSide));

    expanSide = () => {
        this.setState({ expand: !this.state.expand })
        setTimeout(() => {
            if (!this.state.expand) {
                wrapper.classList.add("hide")
            } else {
                wrapper.classList.remove("hide")
            }
        }, 100);
    }

    active = (e) => {
        this.setState({ subexpand: !this.state.subexpand });
        this.setState({ element: e.name });
    }
    render() {
        return (
            <Layout
                onPress={this.expanSide}
                state={this.state.expand}
                onActive={val => this.active(val)}
                list={this.state.list}
                element={this.state.element}
                subexpand={this.state.subexpand}
            />
        );
    }
}

export default Sidebar;