import React, { useState } from 'react'
import Header from '../../components/template/contentheader/'
import axios from 'axios'
import ModalUsuario from './modalUser'
import GridUser from './gridUser'
import Notifications, {notify} from 'react-notify-toast';
import {Loading} from './../../theme-source/material-ui/components/loading'
const LayoutUsuario = () => {

    const [showModal, setShowModal] = useState(false)

    const [searchText, setSearchText] = useState('')

    const [userSave, setUserSave] = useState(0)

    const [isLoading, setLoading] = useState(0)

    const [user, setUser] = useState(null)

    const handleSetUser = (user) => {
        setUser(user)
    }

    const handleLoading = (value) => {
        setLoading(value)
    }

    const handleUserSave = (value) => {
        setUserSave(value)
    }
    const handleToggleModal = (value) => {
        setShowModal(value)
    }

    const handleSearchText = (e) => {
        setSearchText(e.target.value)
    }


    return(
        <div className="content">
            <Notifications options={{zIndex: 2000}} />
            <Header>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-9">
                            <button className="btn btn-primary btn-sm" onClick={()=>{handleToggleModal(true)}}><i className="fas fa-plus"></i> Nuevo Usuario</button>
                        </div>
                        <div className="col-sm-3 d-flex justify-content-end">
                            <input type="text" onChange={handleSearchText} className="form-control" placeholder="buscar usuario"></input>
                        </div>
                    </div>
                </div>
            </Header>

            <div className="wrapper-content-grid">
                <GridUser 
                    searchText={searchText} 
                    userSave={userSave}
                    toggleModal={handleToggleModal}
                    handleSetUser={handleSetUser}
                    notify={notify}
                />    
            </div>

            <ModalUsuario 
                show={showModal} 
                size='lg' 
                toggleModal={handleToggleModal}
                handleUserSave = {handleUserSave} //registra si un asuario ha sido registrado
                notify={notify}
                handleLoading={handleLoading}
                isLoading={isLoading}
                user={user}
                setUser={setUser}
                
            />

            { isLoading && <Loading />}
        </div>
    )
}
export default LayoutUsuario