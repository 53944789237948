import React from 'react'
import Icon from '../../../components/template/icon'
import './style.scss'
import Card from './card'
function Layout(props) {
    return (
        <section className="content-card">
            {props.list.map((item, i) =>
                <Card
                    key={i}
                    title={item.title}
                    icon={item.icon + " f" + (i + 1)}
                    quantity={item.quantity}
                    toup={item.toup}
                />
            )
            }

        </section>
    )
}
export default Layout;