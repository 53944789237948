import React, { Fragment, useState, } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Sidenav from './components/sidenav'
import Navbar from './components/navbar'
import Accouns from './components/account';
import Layout from './components/template/layout'
import API from './util/api'
import './util/main';


import {UserContext} from './context/UserContext';

function App() {

  const initialValueLogin = (localStorage.getItem('logeaded'))?JSON.parse(localStorage.getItem('logeaded')):false

  const [login, setLogin] = useState(Boolean(initialValueLogin))

  const [modules, setModules] = useState([])



  const logeaded = () => {

    setLogin(!login)

  }

  if (!login) {
    return (
      <UserContext.Provider value={{
        login,
        logeaded,
        setModules,
        modules        
      }}>
        <Router>  
        <Accouns />
        </Router>
      </UserContext.Provider>
    )
  }
  
  return (
    <UserContext.Provider value={{
        login,
        logeaded,
        setModules,
        modules
    }}>
      <Fragment>
        <Router>      
          <Route path='/:element?' component={Sidenav} />
          <Navbar />
          <Layout />
        </Router>
      </Fragment>
    </UserContext.Provider>

  );
}

export default App;
