import React from 'react'
import { NavLink, Link } from "react-router-dom";
import Icon from '../../components/template/icon'

function ListItems(props) {
    
    return (
        <ul className="d-menu">
            {props.list.map((item, i) =>
                item.children != null ?
                    (
                        <li key={i}
                            className={props.element == (item.url) ? 'active' : ''}
                            onClick={() => props.onActive({ name: item.url, type: 'sub' })}
                        >
                            <span className="item">
                                <Icon name={item.icon} />
                                <span>{item.name}</span>
                                <Icon variant="right" name="chevron-right" />
                            </span>
                            <ul className="subItem">
                                {item.children.map((child, index) =>
                                    <li key={index} 
                                        onClick={() => props.onActive({ name: child.url, type: 'sub' })}
                                    >
                                        <NavLink to={'/' + child.url}>
                                            <span className="item">
                                                {child.name}
                                            </span>
                                        </NavLink>
                                    </li>
                                )}
                            </ul>
                        </li>
                    ) :
                    (
                        <li key={i}
                            className={(props.element == item.url) ? 'active' : ''}
                            onClick={() => props.onActive({ name: item.url, type: '' })}
                        >
                            <NavLink to={'/' + item.url}>
                                <span className="item">
                                    <Icon name={item.icon} />
                                    <span>{item.name}</span>
                                </span>
                            </NavLink>
                        </li>
                    )

            )
            }
        </ul >
    )
}

export default ListItems;