import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal';
//import "bootstrap/dist/css/bootstrap.min.css";
import API from './../../util/api'
import CONSTANTS from '../../util/constants'
import { Portal } from '@material-ui/core';
const ModalUser = (props) => {


    const [profiles, setProfiles] = useState([])
    //const [categories, setCategories] = useState([])  

    const [id, setId] = useState('0')
    const [name, setName] = useState('')
    const [lastname, setLastname] = useState('')
    const [profile, setProfile] = useState('0')
    //const [category, setCategory] = useState('3')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [superadmin, setSuperadmin] = useState(false)   
    
    const initialValuesError = {
        name:'',
        lastname:'',
        profile:'',
        //category:'',
        email:'',
        password:'',
        superadmin:''
    }
    const [formError, setFormError] = useState(initialValuesError)

    const handleProfiles = (profiles) => {
        setProfiles(profiles)
    }
    /*const handleCategories = (categories) => {
        setCategories(categories)
    } */
    
    /*const ListOptionsCategories = categories.map((categoryItem)=>(
        <option key={categoryItem.id} value={categoryItem.id}>{categoryItem.description}</option>
    ))*/

    const ListOptionsProfiles = profiles.map((profile)=>(
        <option key={profile.id} value={profile.id}>{profile.description}</option>
    ))

    useEffect(()=>{
        cleanValuesFieldsForm()
        cleanFieldsFormErrors()
        //get data
        if (props.show) {
            props.handleUserSave(0)
            const ENDPOINT = CONSTANTS.url_api+'createUser'
            axios.get(ENDPOINT)
                 .then(res => {
                     let response = res.data;
                     handleProfiles(response.profiles)
                     //handleCategories(response.categories)
                 })
        }

        if (props.user !== null) {
            const {user} = props
            setId(user.id)
            setName(user.name)
            setLastname(user.lastname)
            setProfile(user.profile_id)
            //setCategory(user.category_id)
            setEmail(user.email)
            setSuperadmin(user.superadmin=='1'?true:false)
        }

    },[props.show])




    const handleName = (e) => {
        setName(e.target.value)
    }
    const handleLastname = (e) => {
        setLastname(e.target.value)
    } 
    const handleProfile = (e) => {
        setProfile(e.target.value)
    } 
    /*const handleCategory = (e) => {
        setCategory(e.target.value)
    }  */           
    const handleEmail = (e) => {
        setEmail(e.target.value)
    } 
    const handlePassword = (e) => {
        setPassword(e.target.value)
    }
    const handleSuperadmin = (e) => {
        setSuperadmin(!superadmin)
    }     
    const save = () => {
        
        props.handleLoading(true)

        cleanFieldsFormErrors()

        const formData = new FormData()
        
        formData.append("name", name)
        formData.append("lastname", lastname)
        formData.append("profile", profile)
        //formData.append("category", category)
        formData.append("email", email)
        formData.append("password", password)
        formData.append("superadmin", (superadmin)?'1':'0')

        const {user} = props
        if (user !== null) {
            formData.append("id", user.id)
        }

        const ENDPOINT = CONSTANTS.url_api+'saveUser'

        axios.post(ENDPOINT, formData)
            .then((res)=>{

                props.handleLoading(false)

                const response = res.data
                if (response.status == CONSTANTS.st_api_ok) {
                    props.notify.show('Usuario registrado.','success',2500)
                    props.handleUserSave(1)
                    props.setUser(null)
                    props.toggleModal(false)
                }else{
                    if (response.typeError == '1') {
                        props.notify.show('Ups, verfifique datos.','error',2500)
                        addFieldsFormErrors(response.errors)
                    }else{

                    }
                }
            })        
    }

    const addFieldsFormErrors = (errors) => {
        API.addErrorFieldsForm(errors)
        setFormError(errors)       
    }
    const cleanFieldsFormErrors = () => {
        API.cleanFieldsForm()
        setFormError({
            name:'',
            lastname:'', 
            profile:'', 
            //category:'', 
            email:'',
            password:'', 
            superadmin:''
        })
    }

    const cleanValuesFieldsForm = () => {
        setName("")
        setLastname("")
        setProfile("0")
        //setCategory("0")
        setEmail("")
        setPassword("")
        setSuperadmin(false)
    }

    const closeModal = () => {
        props.toggleModal(false)
        props.setUser(null);
    }

    return (
        <Modal show={props.show} size={props.size}>
            <Modal.Header closeButton={false}>
                <Modal.Title>
                    Usuario
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="form-user" className="form">
                    
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label htmlFor="name">Nombres</label>
                                <input type="text" name="name" value={name} id="name" onChange={handleName} className='form-control form-control-sm'/>
                                <div className="invalid-feedback">
                                    {formError.name}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label htmlFor="lastname">Apellidos</label>
                                <input type="text" name="lastname" value={lastname} id="lastname" onChange={handleLastname} className='form-control form-control-sm'/>
                                <div className="invalid-feedback">
                                    {formError.lastname}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label htmlFor="profile">Perfil</label>
                                <select name="profile" id="profile" value={profile} onChange={handleProfile} className="form-control form-control-sm">
                                    <option key={'0'} value="0">Seleccione</option>
                                    {ListOptionsProfiles}
                                </select>
                                <div className="invalid-feedback">
                                    {formError.profile}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {/*<div className="col-sm-4">
                            <div className="form-group">
                                <label htmlFor="category">Categoría</label>
                                <select name="category"  id="category" value={category} onChange={handleCategory} className="form-control form-control-sm">
                                    <option key={'0'} value="0">Seleccione</option>
                                    {ListOptionsCategories}
                                </select>
                                <div className="invalid-feedback">
                                    {formError.category}
                                </div>
                            </div>
                        </div>
                            */}
                        <div className="col-sm-5">
                            <div className="form-group">
                                <label htmlFor="email">Correo</label>
                                <input type="email" name="email" value={email} id="email" onChange={handleEmail} className="form-control form-control-sm"/>
                                <div className="invalid-feedback">
                                    {formError.email}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label htmlFor="password">Contraseña</label>
                                <input type="password" name="password" value={password} autoComplete="on" id="password" onChange={handlePassword} className="form-control form-control-sm"/>
                                <div className="invalid-feedback">
                                    {formError.password}
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div className="row">  
                        <div className="col-sm-12">
                            <div className="form-group">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" onChange={handleSuperadmin} value={superadmin?'1':'0'} name="superadmin" id="superadmin" checked={superadmin}/>
                                    <label className="form-check-label"> SuperUsuario?</label>
                                    <div className="invalid-feedback">
                                        {formError.superadmin}
                                    </div>
                                </div>                            
                            </div>
                        </div>                
                    </div>                   
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-sm btn-danger" onClick={closeModal}>cerrar</button>
                <button className="btn btn-sm btn-primary" onClick={save} disabled={props.isLoading?true:false}>Guardar</button>
            </Modal.Footer>

        </Modal>
    )
}

export default ModalUser 