document.addEventListener("click", e => {    
    if (e.target.matches('.button')){
        const btn = e.target.querySelector(".button");
        console.log(btn)
    }
    
});


const buttons = document.querySelectorAll('a.button');
buttons.forEach(btn => {
    btn.addEventListener('click', function (e) {

        var target = e.target;
        var rect = target.getBoundingClientRect();
        let ripple = document.createElement("span");
        var x = e.pageY - rect.top - ripple.offsetHeight / 2 - document.body.scrollTop;
        var y = e.pageX - rect.left - ripple.offsetWidth / 2 - document.body.scrollLeft;
        ripple.style.top = x + "px";
        ripple.style.left = y + "px";

        this.appendChild(ripple);
        setTimeout(() => {
            ripple.remove();
        }, 500);

    })
})