import React, { Component } from 'react'
import {useLocation} from 'react-router-dom'
import {UserContext} from './../../context/UserContext';
import axios from 'axios'

import Login from '../../containers/account/login'
import Notifications, {notify} from 'react-notify-toast';
import CONSTANTS from './../../util/constants'
import {Loading} from './../../theme-source/material-ui/components/loading' 
//import Account from '../../containers/account'

class Account extends Component {

    
    static contextType = UserContext

    state = {
        email:'',
        password:'',
        loading:false,
        logueaded:false

    }
    onHandleLogeaded = (value) => {
        this.setState({
            ...this.setState,
            logueaded: value
        })
    }
    onHandleLoading = (value) => {
        this.setState({
            ...this.setState,
            loading: value
        })
    }
    onHandleEmail = (e) => {
        this.setState({
            ...this.setState,
            email: e.target.value
        });
    }

    onHandlePassword = (e) => {
        this.setState({
            ...this.setState,
            password: e.target.value
        });        
    }   

    onSubmit = (e) => {
        e.preventDefault()
        this.onHandleLoading(true)
        let formData = new FormData()
        formData.append('email', this.state.email)
        formData.append('password', this.state.password)
        const ENDPOINT = CONSTANTS.url_api+'login'
        axios.post(ENDPOINT,formData)
        .then(res => {
            this.onHandleLoading(false)
            let response = res.data

            if (response.status == CONSTANTS.st_api_ok) {
                let modules = this.setModules(response.modules)
                let user = this.setUser(response.user)
                localStorage.setItem('logeaded', true)
                localStorage.setItem('sidebar', JSON.stringify(modules))
                localStorage.setItem('user', JSON.stringify(user))
                //this.onHandleLogeaded(true)
                this.context.logeaded()
            }
            if(response.status == CONSTANTS.st_api_fail){
                if (response.typeError == '1') {
                    notify.show('Ingrese los datos','error',2500)
                }else{
                    notify.show(response.msg,'error',2500)
                }
            }

        });
    }

    setUser = (user) => {
        return {
            name: user.name,
            category_id: user.category_id,
            superadmin: user.superadmin
        }
    }
    setModules = (modules) => {
        //modules = JSON.parse(modules)
        let modulesFather = modules.map((father)=>{
            
            let objectFather = {
                "name":father.description,
                "url":father.link,
                "icon":father.icon
            };

            //validate if exists childs
            let childsLength = (father.modulesChilds)?father.modulesChilds.length:0;

            if (childsLength > 0) {

                let Childs = father.modulesChilds.map((child)=>{
                    return {
                        "name":child.description,
                        "url":child.link
                    }
                });

                objectFather.children = Childs

            }

            return objectFather
        })

        return modulesFather

    }

    render() {
        return (
            <div>
                <Notifications options={{zIndex: 2000}} />
                <Login
                    onSubmit={this.onSubmit}
                    onHandleEmail={this.onHandleEmail}
                    onHandlePassword={this.onHandlePassword}
                    data={this.state}
                />
                { this.state.loading && <Loading />}
            </div>
        );
    }
}

export default Account;