import React, { Component } from 'react'
import { NavLink, Link, Redirect } from "react-router-dom";
import Layoutdash from '../../containers/dashboard'
import API from '../../util/api'
import Dash from '../../containers/dashboard'

class Dashboard extends Component {
    state = {
        cards: API.getDashboard().cards,
        grafica: API.getDashboard().grafica
    }    
    render() {
        {
            /*return <Layoutdash
            cards={this.state.cards}
            grafica={this.state.grafica}
            />*/
        }
        return <Redirect to="/documentos" />

    }
}

export default Dashboard;