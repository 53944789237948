import React, { useState } from 'react'
import Header from '../../components/template/contentheader/'
import axios from 'axios'
import ModalProfile from './modalProfile'
import GridProfile from './gridProfile'
import Notifications, {notify} from 'react-notify-toast';
import {Loading} from './../../theme-source/material-ui/components/loading'
const LayoutProfile = () => {

    const [showModal, setShowModal] = useState(false)

    const [searchText, setSearchText] = useState('')

    const [profileSave, setProfileSave] = useState(0)

    const [isLoading, setLoading] = useState(0)

    const [profile, setProfile] = useState(null)

    const handleSetProfile = (profile) => {
        setProfile(profile)
    }

    const handleLoading = (value) => {
        setLoading(value)
    }

    const handleProfileSave = (value) => {
        setProfileSave(value)
    }
    const handleToggleModal = (value) => {
        setShowModal(value)
    }

    const handleSearchText = (e) => {
        setSearchText(e.target.value)
    }


    return(
        <div className="content">
            <Notifications options={{zIndex: 2000}} />
            <Header>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-9">
                            <button className="btn btn-primary btn-sm" onClick={()=>{handleToggleModal(true)}}><i className="fas fa-plus"></i> Nuevo Perfil</button>
                        </div>
                        <div className="col-sm-3 d-flex justify-content-end">
                            <input type="text" onChange={handleSearchText} className="form-control" placeholder="buscar perfil"></input>
                        </div>
                    </div>
                </div>
            </Header>

            <div className="wrapper-content-grid">
                <GridProfile 
                    searchText={searchText} 
                    profileSave={profileSave}
                    toggleModal={handleToggleModal}
                    handleSetProfile={handleSetProfile}
                    notify={notify}
                />    
            </div>

            {
                showModal &&             
                <ModalProfile 
                    show={showModal} 
                    size='lg' 
                    toggleModal={handleToggleModal}
                    handleProfileSave = {handleProfileSave} //registra si un perfil ha sido registrado
                    notify={notify}
                    handleLoading={handleLoading}
                    isLoading={isLoading}
                    profile={profile}
                    setProfile={setProfile}
                    
                />
            }


            { isLoading && <Loading />}
        </div>
    )
}
export default LayoutProfile