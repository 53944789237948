import { data } from './data';

class API {

    getSidebar() {
        return data.sidebar;
    }
    getDashboard() {
        return data.dashboard;
    }
    isLogin() {
        return false;
    }

    getUser() {
        const user = localStorage.getItem('user')
        return JSON.parse(user)
    }

    cleanFieldsForm() {
        let elements = document.getElementsByClassName('is-invalid');
        let count = elements.length
        for(var i = 0; i < count; i++) {
            elements[0].classList.remove('is-invalid')
        }     
    }
    addErrorFieldsForm(errors) {
        if (Object.keys(errors).length > 0) {
            const keys = Object.keys(errors)
            for(var i = 0; i < keys.length; i++) {
                let element = document.getElementById(keys[i])
                if (element) {
                    element.classList.add('is-invalid')
                }
            }

        }         
    }

}

export default new API();