import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal';
//import "bootstrap/dist/css/bootstrap.min.css";
import API from './../../util/api'
import CONSTANTS from '../../util/constants'
const ModalFilterDocument = (props) => {

    const [dateInit, setDateInit] = useState('')
    const [dateEnd, setDateEnd] = useState('')
    const [category, setCategory] = useState('0')

    const [listCategories, setListCategories] = useState([])
    
    const initialValuesError = {
        dateInit:'',
        DateEnd:'',
        category:''
    }

    const [formError, setFormError] = useState(initialValuesError)

    const handleListCategories = (categories) => {
        setListCategories(categories)
    } 
    
    const ListOptionsCategories = listCategories.map((categoryItem)=>(
        <option key={categoryItem.id} value={categoryItem.id}>{categoryItem.description}</option>
    ))

    useEffect(()=>{
        cleanValuesFieldsForm()
        //get data
        if (props.show) {
            axios.get('http://localhost/vvo_front/api/document/showFilter')
                 .then(res => {
                     let response = res.data
                     handleListCategories(response.categories)
                 })
        }

    },[props.show])


    const handleDateInit = (e) => {
        setDateInit(e.target.value)
    }
    const handleDateEnd = (e) => {
        setDateEnd(e.target.value)
    } 
    const handleCategory = (e) => {
        setCategory(e.target.value)
    } 

    const handleFilter = () => {
        props.handleLoading(true)
        const data = {
            dateInit: dateInit,
            dateEnd: dateEnd,
            category: category
        }

        props.handleDataFilters(data)

        props.handleLoading(false)

    }

    const cleanValuesFieldsForm = () => {
        setDateInit("")
        setDateEnd("")
        setCategory("0")
    }

    const closeModal = () => {
        props.toggleModal(false)
    }

    return (
        <Modal show={props.show} size={props.size}>
            <Modal.Header closeButton={false}>
                <Modal.Title>
                    Filtros
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="form-filter" className="form">
                    
                    <div className="row">
                        <div className="col-sm-12">
                            <p>La fecha hace referencia a la (Fecha de Ingreso)</p>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label htmlFor="dateInit">Fecha Inicial</label>
                                <input type="date" name="dateInit" value={dateInit} id="dateInit" onChange={handleDateInit} className='form-control form-control-sm'/>
                                <div className="invalid-feedback">
                                    {formError.dateInit}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label htmlFor="dateEnd">Fecha Final</label>
                                <input type="date" name="lastdateEndname" value={dateEnd} id="dateEnd" onChange={handleDateEnd} className='form-control form-control-sm'/>
                                <div className="invalid-feedback">
                                    {formError.dateEnd}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label htmlFor="category">Categoría</label>
                                <select name="category"  id="category" value={category} onChange={handleCategory} className="form-control form-control-sm">
                                    <option key={'0'} value="0">Seleccione</option>
                                    {ListOptionsCategories}
                                </select>
                                <div className="invalid-feedback">
                                    {formError.category}
                                </div>
                            </div>
                        </div>                        

                    </div>                   
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-sm btn-danger" onClick={closeModal}>cerrar</button>
                <button className="btn btn-sm btn-primary" onClick={handleFilter}>Filtrar</button>
            </Modal.Footer>

        </Modal>
    )
}

export default ModalFilterDocument 