import React ,{useState, useEffect}from 'react'
import {Loading} from './../../theme-source/material-ui/components/loading' 
import Paper from '@material-ui/core/Paper'
import {
  PagingState,
  CustomPaging,
} from '@devexpress/dx-react-grid'
import {
    Grid,
    VirtualTable,
    TableColumnResizing,
    TableHeaderRow,
    PagingPanel,
} from '@devexpress/dx-react-grid-material-ui'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import axios from 'axios'
import CONSTANTS from '../../util/constants'

const Root = props => <Grid.Root {...props} style={{ height: '100%' }} />;

const GridUser = ({searchText, userSave, handleSetUser, toggleModal, notify}) => {

    //headers grid
    const ConfigColumns = [
        {name:'action', title:' '},
        {name:'name', title:'NOMBRES'},
        {name:'lastname', title:'APELLIDOS'},
        {name:'email', title:'CORREO'},
        //{name:'category', title:'CATEGORÍA'},
        {name:'profile', title:'PERFIL'},
    ]
    //width cells
    const [columnWidths, setColumnWidths] = useState([
        { columnName: 'action', width: 80 },
        { columnName: 'name', width: 200 },
        { columnName: 'lastname', width: 200 },
        { columnName: 'email', width: 240 },
        //{ columnName: 'category', width: 240 },
        { columnName: 'profile', width: 240 }
      ]);

    const [columns] = useState(ConfigColumns)
    const [rows, setRows] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [pageSize] = useState(20)
    const [currentPage, setCurrentPage] = useState(0)
    const [loading, setLoading] = useState(false)
    const [searchTextOld, setSearchTextOld] = useState('')
    
    const handleButtonEdit = (user) => {
        console.log(user)
        toggleModal(true)
        handleSetUser(user)
    }

    const deleteUser = (user) => {
        setLoading(true)
        const ENDPOINT = CONSTANTS.url_api+'user/delete/'+user.id
        axios.get(ENDPOINT)
                .then((resp)=>{
                    const response = resp.data;
                    setLoading(false)
                    if (response.status == CONSTANTS.st_api_ok) {
                        notify.show(response.msg,'success',2500)
                        loadDataUser()
                    }
                    if (response.status == CONSTANTS.st_api_fail) {
                        notify.show(response.msg,'error',2500)
                    }
                })        
    }
    const handleButtonDelete = (user) => {
        confirmAlert({
            closeOnEscape: false,
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                    <h1>Confirmación?</h1>
                    <p>Desea eliminar al usuario "{user.name+' '+user.lastname}"?</p>
                    <div className="options">
                        <button className="btn btn-danger" onClick={onClose}>No</button>
                        <button className="btn btn-success"
                            onClick={() => {
                                deleteUser(user)
                                onClose()
                            }}
                        >
                        Sí
                        </button>

                    </div>
                </div>
              );
            }
          });   
    }

    const getFormatData = (users) => {

        return users.map((user)=>{

            let options = (
                    <div className="dropdown">
                    <button className="btn btn-success btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fa fa-cogs"></i>
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a className="dropdown-item" onClick={()=>{handleButtonEdit(user)}}><i className="fa fa-edit"></i> Editar</a>
                        <a className="dropdown-item" onClick={()=>{handleButtonDelete(user)}}><i className="fa fa-trash"></i> Eliminar</a>
                    </div>
                    </div>
                )
                
            return {
                name: user.name,
                lastname: user.lastname,
                email: user.email,
                //category: user.category.description,
                profile: user.profile.description,
                action: options
            }
        })
    }


    const loadDataUser = () => {

        setLoading(true)

        setSearchTextOld(searchText)

        if (searchTextOld != searchText) {
            setCurrentPage(0)
        } 
        


        const formData = new FormData()
        formData.append('take', pageSize)
        formData.append('skip', (pageSize * currentPage))
        formData.append('search', searchText)

        const ENDPOINT = CONSTANTS.url_api+'user/getMainList'

        axios.post(ENDPOINT, formData)
                .then((resp)=>{
                    const response = resp.data;
                    setLoading(false)
                    response.data = getFormatData(response.data)
                    setRows(response.data)
                    setTotalCount(response.totalCount)
                })

    }

    useEffect(()=>{
        loadDataUser()
    },[currentPage, searchText, userSave])


    return(
        <Paper className='grid-content'>
            <Grid rows={rows} columns={columns} rootComponent={Root}>
                <VirtualTable height="auto"/>
                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    />                
                <CustomPaging totalCount={totalCount} />
                <TableColumnResizing
                columnWidths={columnWidths}
                onColumnWidthsChange={setColumnWidths}
                />                
                <TableHeaderRow />
                <PagingPanel />
                { loading && <Loading />}
            </Grid>
        </Paper>      
    )
}

export default GridUser