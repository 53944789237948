import React from 'react'
import '@fortawesome/fontawesome-free/css/all.min.css';
function Icon(props) {
    let name = `fas fa-${props.name} ${props.size ? props.size : ''} ${props.variant ? props.variant:''}`;
    return (
        <i
            className={name}
        > </i>
    )
}

export default Icon;