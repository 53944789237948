import React from 'react'
import './style.scss'
import Listitems from './listitems'
import Logo from './logo'

function Sidenav(props) {
    const { innerWidth: width } = window;
    return (
        <section id="sidebar" className={(!props.state ? 'hide' : '') + (width < 600 ? ' mobile' : '')}>
            <Logo state={props.state} onPress={props.onPress} />
            <Listitems list={props.list} element={props.element} onActive={(e)=>props.onActive(e)}/>
        </section>
    )
}

export default Sidenav;

