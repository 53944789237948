import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from '../account';
import Dashboard from '../dashboard';
import Usuario from '../usuario';
import Perfil from '../perfil';
import Documento from '../documento';

import API from '../../util/api'
const routes = [
    {
        path: '/',
        exact: true,
        main: () => <Dashboard />
    },
    {
        path: '/dashboard',
        exact: true,
        main: () => <Dashboard />
    },
    {
        path: '/usuarios',
        exact: true,
        main: () => <Usuario />
    },
    {
        path: '/perfiles',
        exact: true,
        main: () => <Perfil />
    },
    {
        path: '/documentos',
        exact: true,
        main: () => <Documento />
    },        
    {
        path: '/shoelaces',
        main: () => <h2>Shoelaces</h2>
    }
]

function Layout(props) {
    return (

        <section id="content-page">
            {
                routes.map((route) => (
                    <Route
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        component={route.main}
                    />
                ))
            }
        </section>

    );
}
export default Layout;