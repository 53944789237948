import React, { Component } from 'react'
import Nav from '../../containers/navbar'

let element = document.getElementById("wrapper");
let sizesm = 600;
class Navbar extends Component {
    state = {
        expand: false
    }
    componentDidMount = () => {
        const { innerWidth: width } = window;
        if (width < sizesm) {
            element.classList.add("mobile")
        } else {
            element.classList.remove("mobile")
        }
    }
    useEffect = (() => { }, window.addEventListener('resize', this.componentDidMount));
    onShow = () => {
        this.setState({ expand: !this.state.expand })        
        setTimeout(() => {            
            if (this.state.expand) {
                element.classList.add("active")
            } else {
                element.classList.remove("active")
            }
        }, 100);
        
    }
    render() {        
        return <Nav onPress={this.onShow} />;
    }
}

export default Navbar;