import React, { useState } from 'react'
import Header from '../../components/template/contentheader/'
import axios from 'axios'
import ModalFilter from './filter'
import GridDocument from './gridDocument'
import Notifications, {notify} from 'react-notify-toast';
import {Loading} from './../../theme-source/material-ui/components/loading'
const LayoutDocument = () => {

    const [showModal, setShowModal] = useState(false)

    const [searchText, setSearchText] = useState('')

    const [dataFilters, setDataFilters] = useState({
        dateInit: '',
        dateEnd: '',
        category:''
    })

    const [documentSave, setDocumentSave] = useState(0)

    const [isLoading, setLoading] = useState(0)

    const [document, setDocument] = useState(null)

    const handleSetDocument = (document) => {
        setDocument(document)
    }

    const handleLoading = (value) => {
        setLoading(value)
    }

    const handleDocumentSave = (value) => {
        setDocumentSave(value)
    }
    const handleToggleModal = (value) => {
        setShowModal(value)
    }

    const handleSearchText = (e) => {
        setSearchText(e.target.value)
    }

    const handleDataFilters = (data) => {
        setDataFilters(data)
    }


    return(
        <div className="content">
            <Notifications options={{zIndex: 2000}} />
            <Header>
                <div className="container-fluid">
                    <div className="row">
                        
                        <div className="col-sm-9">
                            {

                                <button className="btn btn-primary btn-sm" onClick={()=>{handleToggleModal(true)}}><i className="fas fa-plus"></i> Agregar Filtros</button>
                            }
                        </div>
                        <div className="col-sm-3 d-flex justify-content-end">
                            <input type="text" onChange={handleSearchText} className="form-control" placeholder="buscar documento"></input>
                        </div>
                    </div>
                </div>
            </Header>

            <div className="wrapper-content-grid">
                <GridDocument 
                    searchText={searchText}
                    dataFilters={dataFilters} 
                    documentSave={documentSave}
                    toggleModal={handleToggleModal}
                    handleSetDocument={handleSetDocument}
                    notify={notify}
                />    
            </div>
            
            {

                 <ModalFilter 
                     show={showModal} 
                     size='lg' 
                     toggleModal={handleToggleModal}
                     handleDataFilters={handleDataFilters}
                     notify={notify}
                     handleLoading={handleLoading}
                     isLoading={isLoading}
                 />
        
            }

            { isLoading && <Loading />}
        </div>
    )
}
export default LayoutDocument