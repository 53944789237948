import React from 'react';

function Logo(props) {
    const logo = 'logo ' + (!props.state ? 'justify-content-center' : '');
    const active = !props.state ? 'hide' : '';
    return (
        <>
            <div className={logo}>
                {active == '' &&
                    <span><strong>OTES S.A</strong></span>
                }
                <a id="menu-bar" onClick={props.onPress}>
                    <i className={active}></i>
                </a>
            </div>
        </>
    )
}
export default Logo;