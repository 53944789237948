import React, { Fragment, useState, useContext, useEffect } from 'react'
import {Redirect} from 'react-router-dom'
import { Form, Dropdown } from 'react-bootstrap'
import {UserContext} from './../../context/UserContext'

import './style.scss'

const Navbar = (props) => {

    const userContext = useContext(UserContext)
    
    const [user, setUser] = useState({})

    const [a, setA] = useState(0)

    useEffect(()=>{
        const dataUser = JSON.parse(localStorage.getItem('user'))
        setUser(dataUser)
    },[])

    const logout = () => {
        localStorage.setItem('logeaded', 'false')
        localStorage.removeItem('sidebar')
        localStorage.removeItem('user')
        window.location.href = "/"
        
    }

    return (
        <Fragment>
            <div id="navbar" className="px-2">                
                <div className="d-none d-lg-block" >
                    
                </div>
                <div className="account">
                    <Dropdown>
                        <Dropdown.Toggle className="custom-drop">
                            <img className="user-avatar" src="./../../assets/images/logo_user.png" alt="" />
                            <span><i className="fas fa-user"></i> {user.name}</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {/*<Dropdown.Item href="#/action-2">Perfil</Dropdown.Item>*/}
                            <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </Fragment>
    )
}


export default Navbar;