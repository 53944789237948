import React ,{useState, useEffect}from 'react'
import {Loading} from './../../theme-source/material-ui/components/loading' 
import Paper from '@material-ui/core/Paper'
import {
  PagingState,
  CustomPaging,
} from '@devexpress/dx-react-grid'
import {
    Grid,
    VirtualTable,
    TableColumnResizing,
    TableHeaderRow,
    PagingPanel,
} from '@devexpress/dx-react-grid-material-ui'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import axios from 'axios'
import CONSTANTS from '../../util/constants'

const Root = props => <Grid.Root {...props} style={{ height: '100%' }} />;

const GridDocument = ({dataFilters,searchText, documentSave, handleSetDocument, toggleModal, notify}) => {

    //headers grid
    const ConfigColumns = [
        {name:'action', title:' '},
        {name:'description', title:'DESCRIPCIÓN'},
        {name:'category', title:'CATEGORÍA'},
        {name:'date_created', title:'FECHA DE INGRESO'},
        {name:'date_file', title:'FECHA DE RIMAC'}
    ]
    //width cells
    const [columnWidths, setColumnWidths] = useState([
        { columnName: 'action', width: 100 },
        { columnName: 'description', width: 500 },
        { columnName: 'category', width: 200 },
        { columnName: 'date_created', width: 200 },
        { columnName: 'date_file', width: 240 }
      ]);

    const [columns] = useState(ConfigColumns)
    const [rows, setRows] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [pageSize] = useState(20)
    const [currentPage, setCurrentPage] = useState(0)
    const [loading, setLoading] = useState(false)
    const [searchTextOld, setSearchTextOld] = useState('')


    const loadDataDocument = () => {

        setLoading(true)

        setSearchTextOld(searchText)

        if (searchTextOld != searchText) {
            setCurrentPage(0)
        } 
        


        const formData = new FormData()
        formData.append('take', pageSize)
        formData.append('skip', (pageSize * currentPage))
        formData.append('search', searchText)
        formData.append('category', dataFilters.category)
        formData.append('dateInit', dataFilters.dateInit)
        formData.append('dateEnd', dataFilters.dateEnd)                

        const ENDPOINT = CONSTANTS.url_api+'document/getMainList'

        axios.post(ENDPOINT, formData)
                .then((resp)=>{
                    const response = resp.data
                    setLoading(false)
                    response.data = getFormatData(response.data)
                    setRows(response.data)
                    setTotalCount(response.totalCount)
                })

    }
    const  downloadDocument = ({id}) => {
        setLoading(true)
        notify.show('Descargando documento','success',3500)
        const ENDPOINT = CONSTANTS.url_api+'document/download/'+id
        axios.get(ENDPOINT)
                .then((resp)=>{
                    const response = resp.data;
                    setLoading(false)
                    if (response.status == CONSTANTS.st_api_ok) {
                        notify.show(response.msg,'success',2500)
                        loadDataDocument()
                    }
                    if (response.status == CONSTANTS.st_api_fail) {
                        notify.show(response.msg,'error',2500)
                    }
                }) 
                        
    }

    
    const handleButtonDownload = (document) => {
        confirmAlert({
            closeOnEscape: false,
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                    <h1>Confirmación?</h1>
                    <p>Desea descargar el archivo "{document.description}"?</p>
                    <div className="options">
                  ¿      <button className="btn btn-danger" onClick={onClose}>No</button>
                        <button className="btn btn-success"
                            onClick={  () => {
                                downloadDocument(document)
                                onClose()
                            }}
                        >
                        Sí
                        </button>

                    </div>
                </div>
              );
            }
          });         
    } 
    const getFormatData = (documents) => {

        return documents.map((document)=>{
            
            let options = (
                <div className="dropdown">
                <button className="btn btn-success btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fa fa-cogs"></i>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a className="dropdown-item" onClick={()=>{handleButtonDownload(document)}}><i className="fa fa-download"></i> Descargar</a>
                </div>
                </div>                
            )

            return {
                action: options,
                description: document.description,
                category: document.category.description,
                date_created: document.date_created,
                date_file: document.date_file
            }
        })
    }
    useEffect(()=>{
        loadDataDocument()
    },[currentPage, searchText, documentSave,dataFilters])


    return(
        <Paper className='grid-content'>
            <Grid rows={rows} columns={columns} rootComponent={Root}>
                <VirtualTable height="auto"/>
                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    />                
                <CustomPaging totalCount={totalCount} />
                <TableColumnResizing
                columnWidths={columnWidths}
                onColumnWidthsChange={setColumnWidths}
                />                
                <TableHeaderRow />
                <PagingPanel />
                { loading && <Loading />}
            </Grid>
        </Paper>      
    )
}

export default GridDocument